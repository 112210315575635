import React, { useEffect, useState } from "react"
import Layout from "../retech/components/layout/Layout"


import {
  Accordion, Button, Card, Dropdown, DropdownButton
} from "react-bootstrap"
import { Helmet } from "react-helmet"

import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link, navigate } from "gatsby"
export default function ProductDetailPage({ data }) {
  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const [buttonDisabled, setButtonDisabled] = React.useState(true)
  const [show_confirm_msg, setShowConfirmMsg] = useState(false)
  const [show_Form, setShowForm] = useState(true)
  const [idx, setIdx] = useState([])
  const [isLoading, setLoading] = React.useState(false)
  useEffect(() => {
    setFormState({
      ...formState,
      source: document.querySelector(".sourcep")?.innerText,
    })
  }, [])

  const [formState, setFormState] = useState({
    source: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    priority: "",
    message: "",
  })
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = e => {
    let x = document.getElementById("firstname").value
    let n = document.getElementById("message").value
    let y = document.getElementById("lastname").value
    let z = document.getElementById("phone").value
    let f = document.getElementById("email").value
    let r = document.getElementById("priority").value

    if (x == "" || n == "" || y == "" || z == "" || f == "" || r == "") {
      e.preventDefault()
      return false
    }
    setLoading(true)
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "message",
        "g-recaptcha-response": recaptchaValue,
        ...formState,
      }),
    })
      .then(() => {
        navigate(form.getAttribute("action"))
        setLoading(true)
      })
      .catch(error => alert(error))

    e.preventDefault()
  }
  const handleConfirmMsg = e => {
    let x = document.getElementById("firstname").value
    if (x == "") {
      return false
    }
    let y = document.getElementById("lastname").value
    if (y == "") {
      return false
    }
    let z = document.getElementById("phone").value
    if (z == "") {
      return false
    }
    let f = document.getElementById("email").value
    if (f == "") {
      return false
    }

    e.preventDefault()
    setShowConfirmMsg(true)
    setShowForm(false)
  }
  var callback = function () {
    console.log("Done!!!!")
  }

  // specifying verify callback function
  var verifyCallback = function (response) {
    console.log(response)
  }
  const recaptchaRef = React.createRef()
  const {
    Title,
    DetailImage,
    DetailTitle,
    DetailDescription, CTALink,
    RelatedProduct,
    Applications,
    Materials,
    FAQs,
    FAQImage,
    ContentDescription,
    ContentTitle,
    FileImage,
    file
  } = data.productsJson
  return (
    <Layout>
      <Helmet>
        <title>Retech | {DetailTitle}</title>

        <meta name="description" content={DetailDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={Title} />
        <meta property="og:description" content={DetailDescription} />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={Title} />
        <meta property="twitter:description" content={DetailDescription} />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      <div className="container-fluid px-0 mb-5">
        <div
          className="show_bg_desktop d-none d-sm-block"
          style={{
            backgroundImage:
              "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(" +
              DetailImage +
              ")",
            height: "391px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8 pt-5 mt-5 pr-2">
                <h1 className="font-wight-boder pb-5 sourcep">{Title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container faq">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 d-block d-sm-none mb-5 dropdown-filter">
              <DropdownButton
                id="dropdown-filter"
                title="Product Category"
                variant="secondary"
              >
                {RelatedProduct?.map((node, index) => {


                  return (
                    <>
                      {node.RelatedProductShowLink === true ?
                        <Dropdown.Item href={"/products/" + CTALink + "/" + node.CTALink}>
                          {node.Title}
                        </Dropdown.Item>
                        : (
                          <Dropdown.Item>
                            {node.Title}
                          </Dropdown.Item>
                        )
                      }
                    </>
                  )
                })}
              </DropdownButton>
            </div>
            <div className="col-md-4 d-none d-sm-block">
              <h6 className="font-weight-bold">{DetailTitle}</h6>
              {RelatedProduct?.map((node, index) => {
                return (
                  <>
                    {node.RelatedProductShowLink === true ?
                      <>
                        <small>
                          <Link
                            className={
                              url === node.CTALink ? "active-link" : "primary-color"
                            }
                            to={"/products/" + CTALink + "/" + node.CTALink}
                          >
                            {node.Title}
                          </Link>
                        </small>
                        <br></br>
                      </>
                      : (
                        <>
                          <small>
                            <Link
                              className={"text-dark no-hover"}
                              style={{ textDecoration: "none" }}
                            >
                              {node.Title}
                            </Link>
                          </small>
                          <br></br>
                        </>
                      )
                    }

                  </>
                )
              })}
            </div>
            <div className="col-md-8 pb-5">
              <div className="row">
                <div className="col-md-12">
                  <p className="mb-4 product-par">{DetailDescription}</p>
                </div>
              </div>
              {RelatedProduct?.map((node, index) => {
                return (
                  <>
                    <div className="pt-5 px-0" id={node.Title.replace(" ", "")}>
                      <div className="container">
                        <div className="col-md-12 px-0">
                          <div className="row align-self-center">
                            <div className="col-md-5">
                              {node.RelatedProductShowLink === true ? <>
                                <Link
                                  className={
                                    url === node.CTALink ? "font-weight-bolder mb-3 primary-color" : "font-weight-bolder mb-3 primary-color"
                                  }
                                  to={"/products/" + CTALink + "/" + node.CTALink}
                                >
                                  <img
                                    src={node.Image}
                                    alt="product 1"
                                    className=" w-100"
                                  />
                                </Link>
                              </>
                                : (
                                  <img
                                    src={node.Image}
                                    alt="product 1"
                                    className=" w-100"
                                  />
                                )
                              }
                            </div>
                            <div className="col-md-7">
                              {node.RelatedProductShowLink === true ? <>
                                <Link
                                  className={
                                    url === node.CTALink ? "font-weight-bolder mb-3 primary-color" : "font-weight-bolder mb-3 primary-color"
                                  }
                                  to={"/products/" + CTALink + "/" + node.CTALink}
                                >
                                  <h5 className="font-weight-bolder mb-3 primary-color">
                                    {node.Title}
                                  </h5>
                                </Link>
                              </>
                                :
                                <>
                                  <h5 className="font-weight-bolder mb-3 primary-color">
                                    {node.Title}
                                  </h5>
                                </>
                              }
                              <p className="mb-4 product-par primary-color">
                                {node.SubDescription}
                              </p>
                              <p className="font-weight-bolder mb-2">
                                Materials:
                              </p>
                              <p>
                                {node.Materials}
                              </p>
                              <p className="font-weight-bolder mb-2">
                                Applications:
                              </p>
                              <p>
                                {node.Applications}
                              </p>
                              {node.RelatedProductShowLink === true ? <>
                                <a
                                  className="font-weight-bold download-link"
                                  rel="noreferrer"
                                  href={node.file}
                                  target="_blank"
                                >
                                  <img src='/img/iconfinder_download.svg' alt={FileImage} className="mr-3 mb-2" />{" "}
                                  Download {node.FileText}
                                </a>
                              </>
                                : (
                                  <a
                                    className="font-weight-bold download-link"
                                    rel="noreferrer"
                                    href="/img/blank-1-.pdf"
                                    target="_blank"
                                  >
                                    <img src='/img/iconfinder_download.svg' alt={FileImage} className="mr-3 mb-2" />{" "}
                                    Coming Soon
                                  </a>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
              <div className="container">
                <div className="col-md-12 py-5 ">
                  <div className="row">
                    <div className="col-md-12 pr-5 faq-product-mobile">
                      <h2 className="font-weight-bolder mb-3">{Title} FAQ’s</h2>
                      {FAQs?.map((node, index) => {
                        return (
                          <>
                            <Accordion
                              defaultActiveKey={index + "0"}
                              className="border-top py-3"
                            >
                              <Accordion.Toggle
                                eventKey={"0"}
                                as={Button}
                                variant="link"
                                className="shadow-none w-100 text-left"
                                onClick={() => {
                                  if (idx.includes(index))
                                    setIdx(idx.filter(i => i !== index))
                                  else setIdx([...idx, index])
                                }}
                              >
                                {node.Question}
                                {idx.includes(index) ? (
                                  <FontAwesomeIcon
                                    className="ml-3 float-right fa-lg primary-color"
                                    icon={faChevronCircleRight}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ml-3 float-right fa-lg down primary-color"
                                    icon={faChevronCircleRight}
                                  />
                                )}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={"0"}>
                                <Card.Body className="pb-0">
                                  <p className="product-par">{node.Answer}</p>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Accordion>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  )
}
export const query = graphql`
  query ProjectDetails($CTALink: String) {
    productsJson(CTALink: { eq: $CTALink }) {
      Title
      DetailImage
      DetailTitle
      MetaDescription
      MetaTitle
      DetailDescription
      CTALink
      RelatedProduct {
        FileImage
        CTALink
        Description
        Image
        SubDescription
        Materials
        Applications
        Title
        FileText
        file
        RelatedProductShowLink
      }
      FAQs {
        Answer
        Question
      }
      ContentDescription
      ContentTitle
      FAQImage
    }
  }
`
